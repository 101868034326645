import { css } from '@emotion/react'
import { memo } from 'react'
import { createTheme, Paper } from '@mui/material'
import { useSnapshot } from 'valtio'
import { fontWeight600 } from '~/css/font'
import type { Socket } from '~/modules/SDK/socket2/Socket'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC'
import { useTickChanges } from '~/modules/SDK/ticktock/useTickChanges'
import { processQuoteToReadable } from '~/utils/processQuoteToReadable'
import { useSymbolStore } from '~/modules/symbolQuote/simple/useSymbolStore'
import { useThemeStore } from '~/components/theme/useThemeStore'
import ThemeLayout from '~/components/theme/ThemeLayout'

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: { paper: '#333333' },
  },
})

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    background: { paper: '#DDDDDD' },
    text: {
      primary: '#222222',
    },
  },
})

export const InformationForm = memo(function InformationForm(props: {
  symbol?: Socket.SymbolString
}) {
  const currentSymbol = useSnapshot(useSymbolStore).currentSymbol
  const symbolInfo = useSignalrStoreValueOHLC(state => state.value[props.symbol ?? currentSymbol])
  const quoteChanges = processQuoteToReadable(symbolInfo as Socket.ValueOfOHLC)
  const theme = useThemeStore(s => s.theme)
  const isDark = theme === 'dark'

  const open = symbolInfo?.open ?? 0
  const close = symbolInfo?.close ?? 0
  const high = symbolInfo?.high ?? 0
  const low = symbolInfo?.low ?? 0
  const prevRef = symbolInfo?.prevRef ?? 0
  const volume = symbolInfo?.volume ?? 0
  const tickQty = symbolInfo?.qty ?? 0
  const change = quoteChanges.closeChange
  const changePercent = quoteChanges.closeChangePercentString
  const amp = ((symbolInfo?.high ?? 0) - (symbolInfo?.low ?? 0)).toFixed(2)

  const displayVolume = volume > 1e9 ? (volume / 1e9).toFixed(0) + '億' : volume

  const quoteChange = quoteChanges.closeChange

  const colorUp = isDark ? '#ff0000' : '#fc0000'
  const colorDonw = isDark ? '#00ff00' : '#01c200'

  const colorOpen = open >= prevRef ? colorUp : colorDonw
  const colorClose = close >= open ? colorUp : colorDonw
  const comparePrice = useTickChanges(close)
  const colorTickQty = comparePrice.upThanPrevious
    ? colorUp
    : !comparePrice.sameThenPrevious
    ? colorDonw
    : ''

  const colorVolume = isDark ? '#ffff00' : '#0022ff'
  const colorChange = quoteChange >= 0 ? colorUp : colorDonw
  const colorPrevRef = isDark ? '#ffff00' : '#ff7703'
  const changeSymbol = quoteChange > 0 ? '+' : ''

  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: 7.5% 40% 5% 40% 7.5%;
        width: 100%;
        font-size: 14px;
        ${fontWeight600};
        padding: 8px 0;
      `}
      className={InformationForm.name}
    >
      <div
        css={css`
          ${flex.v.default};
          grid-column: 2 / 3;
          width: 100%;
          gap: 2.5px;
        `}
      >
        <QuoteItem_
          kv={['開盤', open]}
          color={colorOpen}
        />
        <QuoteItem_
          kv={['最高', high]}
          color={colorUp}
        />
        <QuoteItem_
          kv={['最低', low]}
          color={colorDonw}
        />
        <QuoteItem_
          kv={['收盤', close]}
          color={colorClose}
        />
        <QuoteItem_
          kv={['總量', displayVolume]}
          color={colorVolume}
        />
      </div>
      <div
        css={css`
          ${flex.v.default};
          grid-column: 4 / 5;
          width: 100%;
          gap: 2.5px;
        `}
      >
        <QuoteItem_
          kv={['參考', prevRef]}
          color={colorPrevRef}
        />
        <QuoteItem_
          kv={['漲跌', changeSymbol + change]}
          color={colorChange}
        />
        <QuoteItem_
          kv={['幅度', changePercent]}
          color={colorChange}
        />
        <QuoteItem_
          kv={['振幅', amp]}
          color={colorPrevRef}
        />
        <QuoteItem_
          kv={['單量', tickQty]}
          color={colorTickQty}
        />
      </div>
    </div>
  )
})

const QuoteItem_ = memo(function QuoteItem_(props: {
  kv: [string, number | string]
  color: string
}) {
  return (
    <ThemeLayout
      lightTheme={lightTheme}
      darkTheme={darkTheme}
    >
      <Paper
        elevation={0}
        css={css`
          display: grid;
          grid-template-columns: 40% 60%;
          border-radius: 5px;
          width: 100%;
          height: 25px;
        `}
        className={`${SymbolQuoteInfoClasses.button}`}
      >
        <span
          css={css`
            ${flex.h.allCenter};
            grid-column: 1 / 2;
            width: 100%;
          `}
        >
          {props.kv[0]}
        </span>
        <span
          css={css`
            ${flex.h.crossCenter};
            grid-column: 2 / 3;
            width: 100%;
            color: ${props.color};
          `}
        >
          {props.kv[1]}
        </span>
      </Paper>
    </ThemeLayout>
  )
})

export const SymbolQuoteInfoClasses = {
  button: `InformationForm-button`,
}
